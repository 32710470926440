<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              ref="dxGridRef"
              title="Document Types"
              canSelect="none"
              :data-source="dataSource"
              :total-count="totalCount"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-update-cancel="(v) => loadDocumentTypes()"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  :data-field="item.field"
                  :caption="item.caption"
                  v-for="(item, index) in fields"
                  :key="index"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      hide-footer
      v-model="modalShow"
      centered
      @hide="closeModal()"
      size="lg"
    >
      <validation-observer ref="documentTypeRef" #default="{ invalid }">
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Name"
              vid="v-contract_type"
              rules="required"
            >
              <b-form-group label="Name" label-for="v-name">
                <b-form-input
                  maxlength="60"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  v-model="formData.name"
                  name="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="Extension" label-for="v-extension">
              <b-form-input v-model="formData.extension" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="File Type" label-for="v-file_type">
              <b-form-input v-model="formData.file_type" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Icon" label-for="v-icon">
              <b-form-input v-model="formData.icon" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Description" label-form="v-description">
              <b-form-textarea
                rows="4"
                v-model="formData.description"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right">
            <b-form-group>
              <b-button
                size="sm"
                @click="closeModal()"
                variant="danger"
                class="bg-darken-4 mr-1"
              >
                Cancel
              </b-button>
              <b-button
                :disabled="invalid"
                size="sm"
                @click="onSubmit()"
                variant="primary"
                class="bg-darken-4"
              >
                Save
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </section>
</template>

<script>
import documentTypesApi from '@api/document_types';
import { required } from '@validations';
import { queryParameters } from '@/schema';

export default {
  name: 'DocumentTypesPage',
  components: {},
  data: () => ({
    required,
    modalShow: false,
    dataSource: [],
    totalCount: 0,
    page: 0,
    size: 0,
    fields: [
      { caption: 'Name', field: 'name' },
      { caption: 'Description', field: 'description' },
      { caption: 'Extension', field: 'extension' },
      { caption: 'File Type', field: 'file_type' },
      { caption: 'Icon', field: 'icon' },
    ],

    formData: {
      name: null,
      description: null,
      extension: null,
      file_type: null,
      icon: null,
    },
  }),
  watch: {
    page(v) {
      this.loadDocumentTypes();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadDocumentTypes();
      }
    },
  },
  mounted() {
    this.loadDocumentTypes();
  },
  methods: {
    loadDocumentTypes() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      documentTypesApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;
          }

          this.totalCount = meta.page.total;
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const closeModalloadDocumentTypes = () => {
        this.loadDocumentTypes();
        this.closeModal();
        this.toastConfig();
      };

      this.$refs.documentTypeRef.validate().then((success) => {
        let app;

        if (this.formData.id) {
          app = documentTypesApi.update(this.formData);
        } else {
          app = documentTypesApi.add(this.formData);
        }

        if (success) {
          if (app) {
            app
              .then(({ data }) => {
                closeModalloadDocumentTypes();
              })
              .catch(() => {
                this.toastConfig('', 'error');
              })
              .finally(() => {
                //
              });
          }
        }
      });
    },
    onOpenModal(event) {
      this.modalShow = event.value;

      if (event.name === 'edit') {
        this.onEdit(event.data);
      }
    },
    onEdit(data) {
      this.$nextTick(() => {
        this.formData = Object.assign({}, data);
      });
    },
    onDelete(event) {
      documentTypesApi
        .delete(event)
        .then(() => {
          this.loadDocumentTypes();
          this.toastConfig('Delete successful');
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    closeModal() {
      this.modalShow = false;
      const defaultForm = {
        name: null,
        description: null,
        extension: null,
        file_type: null,
        icon: null,
      };

      this.$nextTick(() => {
        this.formData = Object.assign({}, defaultForm);
      });
    },
  },
};
</script>

<style></style>
